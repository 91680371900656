import Java from "../assets/java.svg";
import AndroidStudio from "../assets/android-studio.svg";
import CSS from "../assets/css.svg";
import Figma from "../assets/figma.svg";
import GitHub from "../assets/github.svg";
import Graph from "../assets/graph.svg";
import GraphOne from "../assets/graphOne.svg";
import HTML from "../assets/html.svg";
import Js from "../assets/javascript.svg";
import Jira from "../assets/jira.svg";
import MySql from "../assets/mysql.svg";
import Node from "../assets/nodejs.svg";
import ReactJs from "../assets/react.svg";
import Redux from "../assets/redux.svg";
import Sentry from "../assets/sentry.svg";
import Ts from "../assets/typescript.svg";
import Vscode from "../assets/vscode.svg";
import Vue from "../assets/vuejs.svg";
import TimelineImg from "../assets/Timeline.png";
import MyPic from "../assets/MyPic.jpeg";
import Citi from "../assets/citi.png";
import IIRS from "../assets/iirs.webp";
import BIZGO from "../assets/BizgoLogo.png";
import AWS from "../assets/aws.svg";
import FireBase from "../assets/firebase.svg";
import OneSignal from "../assets/onesignal.svg";
import TapAcademy from "../assets/TapAcademy.png";
import Location from "../assets/location.svg";
import Resume from "../assets/resume.svg";
import Verifi from "../assets/Verifi.png";
import Visa from "../assets/VisaLogo.png";
import Sensa from "../assets/sensa.svg";
import Farshid from "../assets/Farshid.jpeg";
import Ashish from "../assets/Ashish.jpeg";
import Alok from "../assets/AlokSharma.jpeg";
import Prajwal from "../assets/Prajwal.jpeg";
import Nilesh from "../assets/Nilesh.jpeg";
import Zoheb from "../assets/Zoheb.jpeg";
import ASU from "../assets/asu.png";
import CiCd from "../assets/DevOps.svg";
import JSON from "../assets/json.svg";
import API from "../assets/API.png";
import SonarQube from "../assets/SonarQube.svg";
import Coding from "../assets/Coding.svg";
import Probe42 from "../assets/Probe42.avif";
import IOOLabs from "../assets/IOOLabs.png";
import Metstay from "../assets/Metstay.png";
import Job from "../assets/job.svg";
import Strip from "../assets/stripe.svg";
import Express from "../assets/express.svg";
import MongoDB from "../assets/MongoDB.png";
import OpenAI from "../assets/openai.svg";
import SS from "../assets/ScreenShot.svg";
import Shipping from "../assets/shipping.svg";
import JB from "../assets/JB.jpg";

export const MyInformation = {
  toolbarData: {
    resumeLink:
      "https://drive.google.com/file/d/1XuceW_YucdpzTC5In9md_V7qZ-OjoQ4L/view?usp=sharing",
    linkedInUrl: "https://www.linkedin.com/in/adamshoaibk/",
    gitHubUrl: "https://github.com/adamshoaib",
  },
  overviewData: {
    intro: "Hi, my name is",
    name: "Adam Shoaib",
    summaryHeader: "",
    summaryDetails:
      "I am a full-stack software developer, specializing in web and mobile application development. \n 6+ years of Work Experience | Currently working as a Software Developer | Previously work at VISA, IOO Labs, Metastay.",
    conclusion:
      "I am looking forward to connect and interact with founders, managers and anyone looking to hire someone like me to code things from scratch, and bringing ideas to life.",
  },
  skillsData: {
    skillHeader: "Technical Skills",
    skillsList: [
      { name: "JavaScript", logo: Js },
      { name: "TypeScript", logo: Ts },
      { name: "React.Js", logo: ReactJs },
      { name: "Java", logo: Java },
      { name: "HTML", logo: HTML },
      { name: "CSS", logo: CSS },
      { name: "Redux", logo: Redux },
      { name: "React Native", logo: ReactJs },
      { name: "Node.JS", logo: Node },
      { name: "Express.js", logo: Express },
      { name: "MySql", logo: MySql },
      { name: "MongoDB", logo: MongoDB },
      { name: "JSON", logo: JSON },
      { name: "RESTful API", logo: API },
      { name: "Vue", logo: Vue },
      { name: "Android Studio", logo: AndroidStudio },
      { name: "Figma", logo: Figma },
      { name: "GitHub", logo: GitHub },
      { name: "Sentry", logo: Sentry },
      { name: "Jira", logo: Jira },
      { name: "Vscode", logo: Vscode },
      { name: "D3.JS", logo: Graph },
      { name: "Plotly.JS", logo: GraphOne },
      // { name: "AWS", logo: AWS },
      { name: "CI/CD", logo: CiCd },
      { name: "Stripe", logo: Strip },
      { name: "Firebase", logo: FireBase },
      { name: "OneSignal", logo: OneSignal },
      { name: "SonarQube", logo: SonarQube },
    ],
  },
  projectsData: {
    header: "Projects",
    Project_List: [
      {
        name: "Sensa Market",
        date: "Jan 2022",
        logo: Sensa,
        logoRound: true,
        overview:
          "Sensa Market is a option trading platform that provides active traders with all the required resources and tools that they need to stay on the top of the markets.",
        contributions: [
          "Designed over 53 strategies, for calculation and visualization of projected profit and loss.",
          "Simplified complex wireframes, efficiently transforming them into client-focused UI components.",
          "Implemented real-time updates for the application, refreshing values to reflect market changes every 2 seconds.",
          "Incorporated real-time trading graph, live trading news, and AI-driven graph analysis.",
          "From the ground up, designed and developed a robust and secure login flow, seamlessly integrating a subscription model utilizing Stripe.",
        ],
        // projectLink: "https://sensamarket.com/strategies/",
        websitelink: "https://sensamarket.com/strategies/",
        technologies: [
          "React.js",
          "Next.js",
          "Redux",
          "TypeScript",
          "HTML",
          "CSS",
          "AWS",
          "OpenAI",
          "Sentry",
          "CleverTap",
          "SonarQube",
          "Stripe",
          "Trading View Charts",
          "Git",
        ],
      },
      {
        name: "SERG Software",
        subtitle:
          "Sustainability And Circularity Overview (SACO) - SMC Barrier Films",
        date: "March 2023",
        logo: ASU,
        logoRound: false,
        overview:
          "The SERG project is all about making our future more sustainable. This application use different methods to figure out how to keep plastic in use without letting it harm the environment. By analyzing how we manage plastics, SERG aim to find practical ways to reduce their impact on nature.",
        contributions: [
          "Leading the front-end development team, building software products for ASU PhD students.",
          "Designing and Developing a 3D analytical dashboard for Department of Energy.",
          "Developed reusable UI templates and components for cross-project utilization.",
          "Optimizing development schedules to achieve delivery within a concise timeframe of under 3 months.",
        ],
        isPrivate: true,
        // websitelink: "http://ec2-3-88-101-180.compute-1.amazonaws.com/",
        privateMessage: "NDA - sensitive information cannot be disclosed.",
        technologies: [
          "React.js",
          "JavaScript",
          "Node.js",
          "MongoDB",
          "mongoose",
          "Express.js",
          "D3.js",
          "Plotly.js",
          "AWS",
        ],
      },
      {
        name: "BIZGO",
        date: "March 2020",
        logo: BIZGO,
        logoRound: true,
        logoLink: "https://www.bizgo.com/",
        overview:
          "BIZGO offers a SaaS platform that helps SMEs to automate and grow their business. The product leverage the latest technologies to empower SMEs with real-time business data on the go. Intuitively designed for business users without accounting or technical expertise, we enable all SMEs to use our products without training.",
        contributions: [
          "Developed MVP in 3 months using React Native.",
          "Added support for 12 Indian languages.",
          "Worked Closely with leadership team to meet critical timeline needs.",
          "Replicated the mobile app to web application usage using React.js",
          "Developed on-screen signature and live image capture features.",
          "Integrated live chatting and user engagement tools for enhanced functionality.",
        ],
        projectLink:
          "https://drive.google.com/file/d/1M2AeO0dxg9zpIAIlR8UXBd4S65ZxEfWS/view?usp=sharing",
        technologies: [
          "React Native",
          "TypeScript",
          "Vue.js",
          "HTML5",
          "SCSS",
          "React.js",
          "AWS S3",
          "Firebase",
          "CleverTap",
          "Sentry",
          "Git",
          "Jira",
          "Agile Methodologies",
        ],
      },
      {
        name: "Job Search - Tap Academy",
        date: "Jan 2022",
        logo: Job,
        logoRound: false,
        overview:
          "Tap Academy is an ed-tech platform for students who lack coding skills & struggle to get a job. The website helps students to check for companies attending the interview drives and help them to book slots.",
        contributions: [
          "Developed the website from 0 to deployment utilizing React.js and Javascript.",
          "Incorporated admin (B2B) and student (B2C) views to oversee information management with role-based functionality.",
          "Implemented features for viewing information based on user status, distinguishing between logged-in and logged-out users.",
          "Optimized interview drives by aligning students' profiles with the requirements of the companies.",
          "Presently, the Portal boasts an active user base exceeding 30,000 students.",
        ],
        websitelink: "https://aurora-tech.tech/",
        isPrivate: false,
        technologies: [
          "React.js",
          "JavaScript",
          "HTML",
          "CSS",
          "Material UI",
          "PWA",
          "AWS",
        ],
      },
      {
        name: "Portfolio Builder",
        subtitle: "Build Complete professional portfolio in 5 minutes",
        date: "Dec 2023",
        logo: Resume,
        logoRound: false,
        overview:
          "A time and cost-efficient solution for students to swiftly create personalized portfolios by filling out a form, eliminating the need for extensive time and financial investments. P.S. This website is built using the Portfolio Builder.",
        contributions: [
          "Designed and Developed the website in a mere 48 hours.",
          "Enabled dynamic page rendering from user's data.",
          "Enhanced with image uploads, multiple projects, links, adding testimonials, adding certificates, and resumes.",
        ],
        projectLink: "https://github.com/adamshoaib/Portfolio-Builder",
        websitelink: "http://adamshoaib.s3-website.us-east-2.amazonaws.com/",
        isPrivate: false,
        technologies: ["React.js", "JavaScript", "HTML", "SCSS", "AWS"],
      },
      {
        name: "Open AI Image Analysis",
        date: "Feb 2024",
        logo: OpenAI,
        logoRound: false,
        overview:
          "The OpenAI Image Analysis project, built with ReactJS, allows users to upload images and analyze them with customizable prompts using OpenAI's advanced models. ",
        contributions: [
          "Developed image upload and analysis feature utilizing OpenAI.",
          "Conducted proof of concept (POC) for image analysis, which can be easily utilised to other projects.",
        ],
        projectLink: "https://github.com/adamshoaib/OpenAiImageAnalysis",
        isPrivate: false,
        technologies: ["React.js", "JavaScript", "HTML", "SCSS", "Open AI"],
      },
      {
        name: "Coding Platform",
        date: "Jan 2023",
        logo: Coding,
        logoRound: false,
        isPrivate: false,
        // privateMessage:
        //   "Fintech project- sensitive information cannot be disclosed.",
        overview:
          "Coding Platform provides a curated collection of algorithmic problems with integrated OpenAI support for coding doubt resolution. Users begin with unlocked challenges, unlocking progressively challenging ones upon successful completion. A dynamic Employability Score is generated as users solve problems, preparing them for interview drives.",
        contributions: [
          "Designed a comprehensive coding platform enabling code compilation, test case evaluation, and admin capabilities for adding questions with tags seamlessly.",
          "Embed OpenAI in the app for seamless student doubt resolution.",
          "Created a secure login, implemented question unlocking, displaying test results, and added custom test cases.",
        ],
        websitelink: "https://tai.thetapacademy.com/",
        projectLink:
          "https://drive.google.com/file/d/1P2DHBYhQKvHzTAhzybL9LmUWXgBSNkIO/view?usp=sharing",
        technologies: [
          "React.js",
          "JavaScript",
          "HTML",
          "SCSS",
          "OpenAi",
          "RESTful API",
        ],
      },
      {
        name: "Search Easy",
        subtitle: "Search anything in seconds",
        date: "Aug 2022",
        logo: Location,
        logoRound: true,
        overview:
          "SearchEasy is a user-friendly website designed for swift and convenient searches across a myriad of categories such as events, caterers, fitness, loans, and more. Simply utilize the search feature to quickly find comprehensive details and contact information for various options on the go.",
        contributions: [
          "A feature-rich application developed in just three weeks, delivering swift and efficient searches across diverse categories.",
          "Search functionality to search for deals and dealers.",
          "Admin login view to add deals and dealers to the website",
        ],
        websitelink: "https://searcheasy.in/",
        isPrivate: false,
        technologies: ["React.js", "JavaScript", "HTML", "CSS"],
      },
      {
        name: "Screen Analysis",
        date: "Feb 2024",
        logo: SS,
        logoRound: false,
        overview:
          "With the Screen Capture and Analysis project, users can easily capture and examine on-screen content, making it a valuable tool for anyone working with applications. Instead of manually summarizing their screen's contents, users can effortlessly capture their screen and receive insightful analysis of the displayed data.",
        projectLink: "https://github.com/adamshoaib/ScreenAnalysis",
        isPrivate: false,
        technologies: ["Open AI", "React.js", "JavaScript", "HTML", "CSS"],
      },
      {
        name: "Verifi",
        subtitle: "A VISA Solution",
        date: "July 2021",
        logo: Verifi,
        logoRound: false,
        isPrivate: true,
        privateMessage:
          "Fintech project- sensitive information cannot be disclosed.",
        overview:
          "The solution suite helps sellers to resolve fraud and non-fraud disputes efficiently, platform helps banks in analysis and decision-making process for fraudulent transactions. It also supports the establishment of predefined rules to expedite query resolution.",
        contributions: [
          "Implemented case management flow for swift action on fraud transactions.",
          "Implemented user-specific permission granting access to sensitive information.",
          "Increased the efficiency from 65% to 86% by optimizing code, component re-usability.",
        ],
        // websitelink:
        //   "https://www.verifi.com/solutions-sellers/resolve-disputes/",
        technologies: [],
      },
      {
        name: "Rapid Seller Onboarding",
        subtitle: "A VISA Solution",
        date: "Jan 2022",
        logo: Visa,
        logoRound: false,
        isPrivate: true,
        privateMessage:
          "Fintech project- sensitive information cannot be disclosed.",
        overview:
          "RSO expedites merchant onboarding onto the Visa tap-to-phone platform, improving the onboarding time from 3 weeks to a mere 24 hours for swift and efficient integration.",
        contributions: [
          "Developed email change and OTP verification flow for the portal.",
          "Designed forms to collect user information and admins to view user information.",
          "Integrated 3rd party verification processes to verify a merchant",
        ],
        // websitelink:
        //   "https://www.verifi.com/solutions-sellers/resolve-disputes/",
        technologies: [],
      },
      {
        name: "Probe42",
        subtitle: "The Go-to Tool for Financial Institutions",
        date: "Aug 2018",
        logo: Probe42,
        logoRound: true,
        isPrivate: true,
        privateMessage: "Live Product is only accessible to paid customers.",
        overview:
          "Probe42 is a comprehensive data management product that ensures companies' information, including status, directors, charges, shareholdings, and GST details, remains up-to-date. Major banking institutions leverage Probe42 to assess companies' data reliability when making loan decisions. By providing accurate and real-time insights, Probe42 enables banks to make informed decisions, ultimately reducing the risk of bad debt.",
        contributions: [
          "Created a Progressive Web Application (PWA) Dashboard for Probe42, offering real-time access to company data.",
          "Implemented role-based access controls to ensure sensitive information is viewable only by authorized users.",
          "Developed a dashboard for Crown Services, allowing users to monitor the progress of updates and retrigger requests efficiently.",
          "Addressed user concerns related to Probe42 software, discussed identified issues, and prioritized them for resolution.",
        ],
        projectLink: "https://probe42.in/banking.html",
        technologies: [
          "Scala",
          "Vue.js",
          "TypeScript",
          "MongoDB",
          "Java",
          "HTML",
          "CSS",
        ],
      },
      {
        name: "Automated Order Entry",
        subtitle: "The Tool for automated order entry for brokers and carriers",
        date: "April 2024",
        logo: Shipping,
        logoRound: false,
        isPrivate: true,
        overview:
          "Supercharge your logistics. Automate order processing, track shipments in real-time, and gain data-driven insights with our logistics dashboard. It's everything you need to automate your workflow.",
        websitelink:
          "http://automated-order-analysis.s3-website.us-east-2.amazonaws.com",
        projectLink: "https://github.com/adamshoaib/Automated-Order-Entry",
        technologies: ["React.js", "JavaScript", "HTML", "CSS", "Open AI"],
      },
    ],
  },
  timeLineData: {
    header: "Timeline",
    timeLineImage: TimelineImg,
  },
  aboutMeData: {
    header: "About Me",
    myPicture: MyPic,
    details: [
      "Hey there :)",
      "I'm Adam, a graduate student at Arizona State University pursuing a Master's degree in Computer Software Engineering, maintaining a GPA of 3.96.",
      "With 5+ years in software development, I've collaborated across diverse domains—Fintech, ERP, GIS, supply chain, trading, and education. This journey has enriched my insights, skills, and a holistic understanding of software engineering.",
      "Throughout my professional journey, I've developed expertise in full-stack web and mobile app development using Java, React, Vue, React Native, JavaScript, and TypeScript. Working across diverse sectors has broadened my knowledge and refined my skill in tailoring solutions to meet unique client needs.",
      "In my prior roles at startups Metastay and IOO Labs, I collaborated with leadership on developing, testing, and deploying top-tier software solutions. As a Senior Software Engineer at VISA, I specialized in crafting user interfaces to address and resolve customer fraud transactions. I also optimized VISA's projects through code efficiency, component reusability, and testing with Jest and Enzyme.",
      "In my current stint as a Research Aide (SDE) at ASU, I lead the front-end development team, creating tailored software for ASU PhD students within a tight three-month timeline, highlighting my efficiency in delivering high-quality results.",
      "As a trailblazer in building innovative solutions throughout my tenure at different organizations, I bring a strong desire and enthusiasm to contribute in the space of  web application development and mobile application development",
    ],
  },
  githubData: {
    header: "Git Hub",
    userName: "adamshoaib",
  },
  testimonialsData: {
    TestimonialsList: [
      {
        picture: Alok,
        name: "Alok Sharma",
        designation: " Co-Founder & CTO, Freed",
        date: "August 17, 2022",
        relation: "Alok Managed me directly",
        recommendation:
          "Adam is quite sharp and very committed individual. Takes complete responsibility of his assignments and goes extra mile to ensure the quality and completeness. He is a quick learner and highly skilled full stack engineer. \n\n A very good team player, always willing to help other team members. He will be a great asset to any organization. Wishing him all the success in his future endeavours.",
      },
      {
        picture: Zoheb,
        name: "Abdul Zoheb",
        designation: "CEO, Sensa Robotics",
        date: "Jan 8, 2023",
        relation: "Abdul Zoheb Managed me directly",
        recommendation:
          "I had the privilege of working closely with Adam Shoaib as our Technology Lead on the UpLeg App website project. Adam's exceptional tech lead skills were instrumental in the project's success. Adam's expertise in software development especially on react, java script, code optimization, and implementing critical feature ensured that our Upleg App website project not only met but exceeded our expectations. He kept us on track, ensuring timely milestone achievements. His ability to set a clear direction for the rest of development team, foster a collaborative work environment, and consistently deliver high-quality results truly set him apart.\n\n In addition to his technical prowess and leadership skills, Adam possesses great communication and problem-solving abilities. He was always approachable, open to feedback, and willing to go the extra mile to tackle complex challenges. I have no doubt that Adam would be a valuable asset to any team or project, and I wholeheartedly recommend him for any role requiring a dedicated and skilled technology leader.",
      },
      {
        picture: Ashish,
        name: "Ashish Padole",
        designation: "Sr Director of Engineering, Visa",
        date: "August 02, 2022",
        relation: "Ashish worked with me on the same team",
        recommendation:
          "Shoaib joined VISA product development team as Sr UI Developer and played key role on multiple projects over 1+ years. His ability to learn new skills fast, passion towards achieving complex goals and commitment to customer delight are worth noticing based his tenure with the team. He would be great fit for any team and any type of assignment. Thanks for you contribution Shoaib and all the best for next endeavor!!",
      },
      {
        picture: Farshid,
        name: "Farshid",
        designation: "PhD Candidate, ASU",
        date: "September 13, 2023",
        relation: "Farshid Managed me directly",
        recommendation:
          "I had the pleasure of managing Adam during our collaborative research project funded by the REMADE Institute of the Department of Energy (DOE) at Arizona State University. As a software engineer, Adam played a key role in developing software to evaluate the sustainability and circularity of plastic films. Adam consistently impressed me with his technical expertise and dedication to our project. He effectively translated complex sustainability metrics into a user-friendly software interface. His ability to work collaboratively and bring innovative ideas to the table was remarkable. His commitment to our project's goals was evident in his work ethic and enthusiasm. I highly recommend Adam for his technical proficiency, teamwork, and dedication. He is an asset to any project and a promising front-end software engineer. \n Feel free to contact me for more information. \n\n Sincerely, \n Farshid Nazemi \n Graduate Research Associate",
      },
      {
        picture: Nilesh,
        name: "Nilesh Barai",
        designation: "Software Engineer - Sr. Consultant Level at Visa",
        date: "July 24, 2022",
        relation: "Nilesh was senior to me but didn't manage me directly",
        recommendation:
          "I have worked with Adam for almost 1 year at Visa Bangalore. I was amazed with his UI skills and knowledge about Javascript and React.js framework. I found Adam extremely passionate with regards to fulfilling work commitments and completing work well in advance before deadline. \n I wish him all the very best for his higher studies.",
      },
      {
        picture: Prajwal,
        name: "Prajwal Kadariya",
        designation: "Staff UI Engineer at Visa",
        date: "July 21, 2022",
        relation: "Prajwal worked with me on the same team",
        recommendation:
          "Adam is intelligent and has a great eye for details. I worked with Adam on several projects at Visa. He brought the knowledge and skills needed to do all the UI task on hand very effectively.",
      },
    ],
  },
  footerData: {
    linkedInUrl: "https://www.linkedin.com/in/adamshoaibk/",
    emailId: "akareems@asu.edu",
  },
  experienceData: {
    header: "Experience",
    experienceList: [
      {
        companyName: "Jenius Bank",
        logo: JB,
        designation: "Frontend Engineer",
        date: "August 2024 - Present",
        description: [
          "Building platform products in a dynamic startup environment, working closely with a small, agile team. Focused on developing secure, innovative solutions that drive the growth and success of the company."
        ],
      },
      {
        companyName: "Arizona State University",
        logo: ASU,
        designation: "Software Engineer",
        date: "March 2023 - Present",
        description: [
          "Collaborated with ASU Ph.D. students to translate their research into software tools. Designing and Developing multiple 3D analytical dashboards for the chemical industry, to be used by the USA Department of Energy. Wearing multiple hats as developer, product manager, and designer, led projects to completion within 3 months.",
        ],
      },
      {
        companyName: "VISA Inc.",
        logo: Visa,
        designation: "Senior Front-end Engineer",
        date: "September 2021 - July 2022",
        description: [
          "Created a fraud management dashboard for rapid decisions (<24 hrs) and a React.js onboarding solution for VISA's tap-to-phone platform, reducing onboarding to 24 hrs. Enhanced Verifi product efficiency from 65% to 86% through code optimizations and Jest/Enzyme tests. Implemented user-specific permissions for sensitive data access in Verifi.",
        ],
      },
      {
        companyName: "IOO Labs",
        logo: IOOLabs,
        designation: "Software Engineer II",
        date: "March 2020 - August 2021",
        description: [
          "Developed a React Native accounting mobile app for SMEs (MVP in 3 months) with 40K+ downloads. Implemented localization for 12 languages, resulting in a 45% increase in app downloads. Collaborated on designing robust solutions with the Leadership and development teams.",
        ],
      },
      {
        companyName: "Metastay",
        logo: Metstay,
        designation: "Software Developer",
        date: "July 2018 - February 2020",
        description: [
          "Developed Probe42, a PWA for evaluating company performance, reducing bad debt by 95%. Designed a Vue.js platform with 25+ pre-built components, cutting development time by 40%. Conducted effective product demos, addressing client concerns based on profiles.",
        ],
      },
    ],
  },
};
